export const namespaced = true

export const state = () => ({
	active: false,
	title: '',
	message: '',
})

export const getters = {
	active: (state) => state.active,
	title: (state) => state.title,
	message: (state) => state.message,
}

export const actions = {
	push({ commit }, { message, title }) {
		commit('CHANGE_TITLE', title)
		commit('CHANGE_MESSAGE', message)
		commit('CHANGE_ACTIVE', true)
	},
	close({ commit, dispatch }) {
		dispatch('popup/sendCloseAction', null, { root: true })
		commit('CHANGE_ACTIVE', false)
		setTimeout(() => {
			commit('CHANGE_TITLE', '')
			commit('CHANGE_MESSAGE', '')
		}, 100)
	},
	doNotShowAgain({ commit, dispatch }) {
		dispatch('popup/close', null, { root: true })
		return this.$api
			.get('/settings/popup')
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	sendCloseAction({ dispatch }) {
		return this.$api
			.post('/user/popup')
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	CHANGE_ACTIVE: (state, active) => (state.active = active),
	CHANGE_SUCCESS: (state, success) => (state.success = success),
	CHANGE_TITLE: (state, title) => (state.title = title),
	CHANGE_MESSAGE: (state, message) => (state.message = message),
}
