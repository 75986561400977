export const namespaced = true

export const state = () => ({
	studies: [],
	studentEmails: [],
})

export const getters = {
	studies: (state) => state.studies,
	studentEmails: (state) => state.studentEmails,
}

export const actions = {
	fetchStudies({ commit, dispatch }) {
		commit('SET_STUDIES', [])
		return this.$api
			.get('/studies')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDIES', data.studies)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchStudentEmails({ commit, dispatch }) {
		commit('SET_STUDENT_EMAILS', [])
		return this.$api
			.get(`/studies/students`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDENT_EMAILS', data.studentEmails)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	needsRegistration({ dispatch }, { facultyID, universityID }) {
		return this.$api
			.post(`/studies/needs/university/${universityID}/faculty/${facultyID}`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchStudentEmailFromFaculty({ dispatch }, faculty) {
		return this.$api
			.get(`/studies/faculty/${faculty.id}`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	registerStudent({ commit, dispatch }, { facultyID, universityID, degreeID, studentEmail, studyEnded }) {
		return this.$api
			.post(`/studies/register/university/${universityID}/faculty/${facultyID}/degree/${degreeID}`, {
				studentEmail,
				studyEnded,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDIES', data.studies)
					dispatch('studies/fetchStudentEmails', null, { root: true })
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	registerStudentQuick({ commit, dispatch }, { facultyID, universityID, degreeID, studentEmail, studyEnded }) {
		return this.$api
			.post(`/studies/register/quick/university/${universityID}/faculty/${facultyID}/degree/${degreeID}`, {
				studentEmail,
				studyEnded,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDIES', data.studies)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	sendVerifyStudentEmail({ dispatch }, { student }) {
		return this.$api
			.post(`/studies/students/${student.id}/send`, {
				studentEmail: student.email,
			})
			.then(({ data }) => {
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	verifyStudent({ commit, dispatch }, token) {
		return this.$api
			.post('/studies/register/confirm', {
				token,
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
					if (data.setupInfo.verified.value && data.setupInfo.setup.accountType && data.setupInfo.accountType.student.value) {
						dispatch('courses/fetchCourses', null, { root: true })
					}
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeStudentEmail({ dispatch, rootGetters }, { student }) {
		return this.$api
			.delete(`/studies/students/${student.id}/remove`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('studies/fetchStudies', null, { root: true })
					dispatch('studies/fetchStudentEmails', null, { root: true })
					if (
						rootGetters['user/setupInfo'].verified.value &&
						rootGetters['user/setupInfo'].accountType.setup &&
						rootGetters['user/setupInfo'].accountType.student.value
					) {
						dispatch('courses/fetchCourses', null, { root: true })
					}
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	endStudy({ dispatch, rootGetters }, { studyID }) {
		return this.$api
			.delete(`/studies/${studyID}/end`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('studies/fetchStudies', null, { root: true })
					dispatch('studies/fetchStudentEmails', null, { root: true })
					if (
						rootGetters['user/setupInfo'].verified.value &&
						rootGetters['user/setupInfo'].accountType.setup &&
						rootGetters['user/setupInfo'].accountType.student.value
					) {
						dispatch('courses/fetchCourses', null, { root: true })
					}
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	notEndStudy({ dispatch, rootGetters }, { studyID }) {
		return this.$api
			.delete(`/studies/${studyID}/notEnd`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('studies/fetchStudies', null, { root: true })
					dispatch('studies/fetchStudentEmails', null, { root: true })
					if (
						rootGetters['user/setupInfo'].verified.value &&
						rootGetters['user/setupInfo'].accountType.setup &&
						rootGetters['user/setupInfo'].accountType.student.value
					) {
						dispatch('courses/fetchCourses', null, { root: true })
					}
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_STUDIES: (state, studies) => (state.studies = studies),
	SET_STUDENT_EMAILS: (state, studentEmails) => (state.studentEmails = studentEmails),
}
