export const namespaced = true

export const state = () => ({
	active: false,
	success: false,
	message: '',
})

export const getters = {
	active: (state) => state.active,
	success: (state) => state.success,
	message: (state) => state.message,
}

export const actions = {
	push({ commit }, { success, message }) {
		commit('CHANGE_SUCCESS', success)
		commit('CHANGE_MESSAGE', message)
		commit('CHANGE_ACTIVE', true)
	},
	close({ commit }) {
		commit('CHANGE_ACTIVE', false)
		setTimeout(() => {
			commit('CHANGE_SUCCESS', false)
			commit('CHANGE_MESSAGE', '')
		}, 100)
	},
}

export const mutations = {
	CHANGE_ACTIVE: (state, active) => (state.active = active),
	CHANGE_SUCCESS: (state, success) => (state.success = success),
	CHANGE_MESSAGE: (state, message) => (state.message = message),
}
