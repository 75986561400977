export const namespaced = true

const companyDefault = {
	id: 0,
	imgURL: '',
	name: '',
}

export const state = () => ({
	loading: false,
	companies: [],
	company: companyDefault,
})

export const getters = {
	loading: (state) => state.loading,
	companies: (state) => state.companies,
	company: (state) => state.company,
}

export const actions = {
	setLoadingCompany({ commit }, loading) {
		commit('SET_LOADING', loading)
	},
	fetchCompanies({ commit, dispatch }) {
		commit('SET_COMPANIES', [])
		return this.$api
			.get('/companies')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANIES', data.companies)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCompaniesFilter({ commit, dispatch }) {
		commit('SET_COMPANIES', [])
		return this.$api
			.get('/companies/filter')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANIES', data.companies)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCompany({ commit, dispatch }, companyID) {
		commit('REMOVE_COMPANY')
		return this.$api
			.get(`/companies/${companyID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	goToSocial({ dispatch }, { companyID, socialNetwork }) {
		return this.$api
			.post(`/companies/${companyID}/social/${socialNetwork}`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_LOADING: (state, loading) => (state.loading = loading),
	SET_COMPANIES: (state, companies) => (state.companies = companies),
	SET_COMPANY: (state, company) => (state.company = company),
	REMOVE_COMPANY: (state) => (state.company = companyDefault),
}
