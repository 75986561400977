export const namespaced = true

export const state = () => ({
	attendance: null,
	attendances: [],
})

export const getters = {
	attendance: (state) => state.attendance,
	attendances: (state) => state.attendances,
}

export const actions = {
	fetchAttendances({ commit, dispatch }) {
		commit('SET_ATTENDANCES', [])
		return this.$api
			.get('/attendances')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_ATTENDANCES', data.attendances)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_ATTENDANCE: (state, attendance) => (state.attendance = attendance),
	SET_ATTENDANCES: (state, attendances) => (state.attendances = attendances),
	EVENT_ATTENDED: (state, attendance) => state.attendances.unshift(attendance),
	EVENT_MISSED: (state, attendance) => (state.attendances = state.attendances.filter((e) => e.id != attendance.id)),
	DOCUMENT_UPLOADED_EVENT: (state, documents) => (state.attendance.documents = documents),
}
