export const namespaced = true

export const state = () => ({
	faculties: [],
})

export const getters = {
	faculties: (state) => state.faculties,
}

export const actions = {
	fetchFaculties({ commit, dispatch }) {
		commit('SET_FACULTIES', [])
		commit('degrees/SET_DEGREES', [], { root: true })
		return this.$api
			.get('/faculties')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_FACULTIES', data.faculties)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchMinFacultiesFromUniversity({ commit, dispatch }, university) {
		commit('SET_FACULTIES', [])
		commit('degrees/SET_DEGREES', [], { root: true })
		return this.$api
			.get(`/faculties/min/university/${university.id}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_FACULTIES', data.faculties)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_FACULTIES: (state, faculties) => (state.faculties = faculties),
}
