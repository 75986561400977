export const namespaced = true

export const state = () => ({
	universities: [],
})

export const getters = {
	universities: (state) => state.universities,
}

export const actions = {
	fetchMinUniversities({ commit, dispatch }) {
		commit('SET_UNIVERSITIES', [])
		commit('faculties/SET_FACULTIES', [], { root: true })
		commit('degrees/SET_DEGREES', [], { root: true })
		return this.$api
			.get('/universities/min')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_UNIVERSITIES', data.universities)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_UNIVERSITIES: (state, universities) => (state.universities = universities),
}
