export const namespaced = true

export const state = () => ({
	loading: false,
	offer: null,
	offers: [],
})

export const getters = {
	loading: (state) => state.loading,
	offer: (state) => state.offer,
	offers: (state) => state.offers,
}

export const actions = {
	setLoadingOffer({ commit }, loading) {
		commit('SET_LOADING', loading)
	},
	fetchOffer({ commit, dispatch }, offerID) {
		commit('SET_OFFER', null)
		return this.$api
			.get(`/offers/${offerID}`, {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success && data.application) {
					commit('SET_OFFER', data.offer)
					commit('applications/SET_APPLICATION', data.application, { root: true })
				} else if (data.success) {
					commit('SET_OFFER', data.offer)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchOffers({ commit, dispatch }) {
		commit('SET_OFFERS', [])
		return this.$api
			.get('/offers/available', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFERS', data.offers)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchOffersCompany({ commit, dispatch }, { companyID }) {
		commit('SET_OFFERS', [])
		return this.$api
			.get(`/offers/available/company/${companyID}`, {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFERS', data.offers)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	applyOffer({ commit, dispatch }, { offer, templateID }) {
		return this.$api
			.post(`/offers/${offer.id}/apply`, {
				templateID,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_OFFER', data.application.offer)
					commit('applications/SET_APPLICATION', data.application, { root: true })
					commit('applications/OFFER_APPLIED', data.application, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	applyOfferExternal({ dispatch }, offerID) {
		return this.$api
			.post(`/offers/${offerID}/external`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_LOADING: (state, loading) => (state.loading = loading),
	SET_OFFER: (state, offer) => (state.offer = offer),
	SET_OFFERS: (state, offers) => (state.offers = offers),
}
