export const namespaced = true

const qodeDefaultAd = {
	code: 'qode',
	extension: 'jpg',
	name: 'QODE',
	url: 'https://www.qode.pro',
}

const defaultSponsors = {
	desktop: {
		top: qodeDefaultAd,
		bottom: qodeDefaultAd,
	},
	mobile: {
		left: qodeDefaultAd,
		right: qodeDefaultAd,
	},
}

export const state = () => ({
	sponsors: defaultSponsors,
})

export const getters = {
	sponsors: (state) => state.sponsors,
}

export const actions = {
	fetchSponsors({ commit }) {
		return this.$api
			.get('/sponsors')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SPONSORS', data.sponsors)
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('SET_SPONSORS', defaultSponsors)
			})
	},
	followAd({ dispatch }, code) {
		return this.$api
			.post(`/sponsors/${code}/follow`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_SPONSORS: (state, sponsors) => (state.sponsors = sponsors),
}
