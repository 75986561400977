import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/Layout'

import Landing from '@/views/Landing'

const AuthLogin = () => import('@/views/AuthLogin')
const AuthRegister = () => import('@/views/AuthRegister')
const AuthLogout = () => import('@/views/AuthLogout')
const AuthVerify = () => import('@/views/AuthVerify')
const AuthSetup = () => import('@/views/AuthSetup')
const AuthResetPassword = () => import('@/views/AuthResetPassword')

const Course = () => import('@/views/Course')
const CourseAdd = () => import('@/views/CourseAdd')
const CourseExplorer = () => import('@/views/CourseExplorer')
const CourseFeed = () => import('@/views/CourseFeed')
const CourseFeedPosts = () => import('@/views/CourseFeedPosts')
const CourseFeedUploads = () => import('@/views/CourseFeedUploads')
const CourseDetails = () => import('@/views/CourseDetails')
const Courses = () => import('@/views/Courses')

const Newsfeed = () => import('@/views/Newsfeed')
const NewsfeedPosts = () => import('@/views/NewsfeedPosts')
const NewsfeedUploads = () => import('@/views/NewsfeedUploads')

const Studies = () => import('@/views/Studies')
const StudiesAdd = () => import('@/views/StudiesAdd')

const Company = () => import('@/views/Company')
const CompanyProfile = () => import('@/views/CompanyProfile')
const CompanyOffers = () => import('@/views/CompanyOffers')
const CompanyEvents = () => import('@/views/CompanyEvents')

const Companies = () => import('@/views/Companies')

const Offer = () => import('@/views/Offer')
const Offers = () => import('@/views/Offers')

const Applications = () => import('@/views/Applications')

const Attendances = () => import('@/views/Attendances')

const Event = () => import('@/views/Event')
const Events = () => import('@/views/Events')

const Settings = () => import('@/views/Settings')
const SettingsAccount = () => import('@/views/SettingsAccount')
const SettingsDocuments = () => import('@/views/SettingsDocuments')
const SettingsGoogleDrive = () => import('@/views/SettingsGoogleDrive')
const SettingsStudent = () => import('@/views/SettingsStudent')
const SettingsNotifications = () => import('@/views/SettingsNotifications')

const Policies = () => import('@/views/Policies')
const PoliciesCookies = () => import('@/views/PoliciesCookies')
const PoliciesPrivacy = () => import('@/views/PoliciesPrivacy')
const PoliciesTerms = () => import('@/views/PoliciesTerms')

const Contact = () => import('@/views/Contact')
const Exams = () => import('@/views/Exams')
const FAQ = () => import('@/views/FAQ')
const Team = () => import('@/views/Team')

const Sponsors = () => import('@/views/Sponsors')
const SponsorEIC = () => import('@/views/SponsorEIC')
const SponsorFUE = () => import('@/views/SponsorFUE')

const Messages = () => import('@/views/Messages')
const MessagesChat = () => import('@/views/MessagesChat')

const NotificationsCenter = () => import('@/views/NotificationsCenter')

const User = () => import('@/views/User')

import ErrorView from '@/views/ErrorView'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Landing',
		component: Landing,
		meta: { requiresGuest: true },
	},
	{
		path: '/*',
		component: Layout,
		children: [
			{
				path: '/auth/login',
				name: 'AuthLogin',
				component: AuthLogin,
				meta: { requiresGuest: true },
			},
			{
				path: '/auth/register',
				name: 'AuthRegister',
				component: AuthRegister,
				meta: { requiresGuest: true },
			},
			{
				path: '/auth/logout',
				name: 'AuthLogout',
				component: AuthLogout,
			},
			{
				path: '/auth/verify',
				name: 'AuthVerify',
				component: AuthVerify,
			},
			{
				path: '/auth/setup',
				name: 'AuthSetup',
				component: AuthSetup,
				meta: { requiresAuth: true },
			},
			{
				path: '/auth/resetPassword',
				name: 'AuthResetPassword',
				component: AuthResetPassword,
			},
			{
				path: '/newsfeed',
				name: 'Newsfeed',
				component: Newsfeed,
				meta: { requiresAuth: true },
				redirect: { name: 'NewsfeedPosts' },
				children: [
					{ path: 'posts', name: 'NewsfeedPosts', component: NewsfeedPosts, meta: { requiresAuth: true } },
					{ path: 'uploads', name: 'NewsfeedUploads', component: NewsfeedUploads, meta: { requiresAuth: true } },
				],
			},
			{
				path: '/courses',
				name: 'Courses',
				component: Courses,
				meta: { requiresAuth: true },
			},
			{ path: '/courses/add', name: 'CourseAdd', component: CourseAdd, meta: { requiresAuth: true } },
			{
				path: '/courses/:id',
				name: 'Course',
				component: Course,
				meta: { requiresAuth: true },
				redirect: { name: 'CourseExplorer' },
				children: [
					{ path: 'explorer', name: 'CourseExplorer', component: CourseExplorer, meta: { requiresAuth: true } },
					{
						path: 'feed',
						name: 'CourseFeed',
						component: CourseFeed,
						redirect: { name: 'CourseFeedPosts' },
						children: [
							{ path: 'posts', name: 'CourseFeedPosts', component: CourseFeedPosts, meta: { requiresAuth: true } },
							{ path: 'uploads', name: 'CourseFeedUploads', component: CourseFeedUploads, meta: { requiresAuth: true } },
						],
					},
					{ path: 'details', name: 'CourseDetails', component: CourseDetails, meta: { requiresAuth: true } },
				],
			},
			{
				path: '/policies',
				name: 'Policies',
				component: Policies,
			},
			{
				path: '/policies/cookies',
				name: 'PoliciesCookies',
				component: PoliciesCookies,
			},
			{
				path: '/policies/privacy',
				name: 'PoliciesPrivacy',
				component: PoliciesPrivacy,
			},
			{
				path: '/policies/terms',
				name: 'PoliciesTerms',
				component: PoliciesTerms,
			},
			{
				path: '/settings',
				name: 'Settings',
				component: Settings,
				meta: { requiresAuth: true },
				redirect: { name: 'SettingsAccount' },
				children: [
					{ path: 'account', name: 'SettingsAccount', component: SettingsAccount, meta: { requiresAuth: true } },
					{ path: 'documents', name: 'SettingsDocuments', component: SettingsDocuments, meta: { requiresAuth: true } },
					{ path: 'gdrive', name: 'SettingsGoogleDrive', component: SettingsGoogleDrive, meta: { requiresAuth: true } },
					{ path: 'student', name: 'SettingsStudent', component: SettingsStudent, meta: { requiresAuth: true } },
					{ path: 'notifications', name: 'SettingsNotifications', component: SettingsNotifications, meta: { requiresAuth: true } },
				],
			},
			{
				path: '/studies',
				name: 'Studies',
				component: Studies,
				meta: { requiresAuth: true },
			},
			{
				path: '/studies/add',
				name: 'StudiesAdd',
				component: StudiesAdd,
				meta: { requiresAuth: true },
			},
			{
				path: '/offers',
				name: 'Offers',
				component: Offers,
				meta: { requiresAuth: true },
			},
			{
				path: '/offers/:id',
				name: 'Offer',
				component: Offer,
			},
			{
				path: '/applications',
				name: 'Applications',
				component: Applications,
				meta: { requiresAuth: true },
			},
			{
				path: '/events',
				name: 'Events',
				component: Events,
				meta: { requiresAuth: true },
			},
			{
				path: '/events/:id',
				name: 'Event',
				component: Event,
			},
			{
				path: '/schedule',
				name: 'Attendances',
				component: Attendances,
				meta: { requiresAuth: true },
			},
			{
				path: '/companies',
				name: 'Companies',
				component: Companies,
				meta: { requiresAuth: true },
			},
			{
				path: '/companies/:id',
				name: 'Company',
				component: Company,
				redirect: { name: 'CompanyProfile' },
				children: [
					{ path: 'profile', name: 'CompanyProfile', component: CompanyProfile },
					{ path: 'offers', name: 'CompanyOffers', component: CompanyOffers },
					{ path: 'events', name: 'CompanyEvents', component: CompanyEvents },
				],
			},
			{
				path: '/messages',
				name: 'Messages',
				component: Messages,
				meta: { requiresAuth: true },
				children: [{ path: ':id', name: 'MessagesChat', component: MessagesChat, meta: { requiresAuth: true } }],
			},
			{
				path: '/contact',
				name: 'Contact',
				component: Contact,
			},
			{
				path: '/exams',
				name: 'Exams',
				component: Exams,
				meta: { requiresAuth: true },
			},
			{
				path: '/faq',
				name: 'FAQ',
				component: FAQ,
			},
			{
				path: '/notifications',
				name: 'NotificationsCenter',
				component: NotificationsCenter,
				meta: { requiresAuth: true },
			},
			{
				path: '/user',
				name: 'User',
				component: User,
				meta: { requiresAuth: true },
			},
			{
				path: '/team',
				name: 'Team',
				component: Team,
			},
			{
				path: '/sponsors/',
				name: 'Sponsors',
				component: Sponsors,
			},
			{
				path: '/sponsors/eic',
				name: 'SponsorEIC',
				component: SponsorEIC,
				meta: { requiresSuper: true },
			},
			{
				path: '/sponsors/fue',
				name: 'SponsorFUE',
				component: SponsorFUE,
				meta: { requiresSuper: true },
			},
			{
				path: '/error/:type',
				name: 'Error',
				component: ErrorView,
				props: true,
			},
			{
				path: '/*',
				redirect: { name: 'Error', params: { type: '404' } },
			},
		],
	},
]

const router = new VueRouter({
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach((to, from, next) => {
	document.title = to.name

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.getters['auth/authenticated']) {
			next({
				name: 'AuthLogin',
				query: { redirect: to.fullPath },
			})
		} else {
			next()
		}
	}

	if (to.matched.some((record) => record.meta.requiresSuper)) {
		if (!store.getters['user/setupInfo'].superAccess) {
			next({
				name: 'Error',
				params: { type: '404' },
			})
		} else {
			next()
		}
	}

	if (to.matched.some((record) => record.meta.requiresHyper)) {
		if (!store.getters['user/setupInfo'].hyperAccess) {
			next({
				name: 'Error',
				params: { type: '404' },
			})
		} else {
			next()
		}
	}

	if (to.matched.some((record) => record.meta.requiresGuest)) {
		if (store.getters['auth/authenticated']) {
			next({ name: 'NewsfeedPosts' })
		} else {
			next()
		}
	}

	next()
})

export default router
