export const namespaced = true

const currentUserDefault = {
	email: '',
	name: {
		first: '',
		last: '',
		full: '',
	},
	locale: {
		key: 'en',
		name: 'English',
	},
}
const setupInfoDefault = {
	accountType: {
		value: '',
		student: {
			value: false,
		},
		alumni: {
			value: false,
		},
	},
	setup: {
		accountType: false,
		gDriveEmail: false,
		studies: false,
		description: false,
		lookingFor: false,
		profilePicture: false,
		progress: 0,
		nextStep: {
			description: '',
			route: '',
		},
		ready: false,
	},
	studies: {
		verifiedStudies: false,
		verifiedOpenedStudies: false,
		allAlumni: false,
	},
	verified: {
		value: false,
		date: null,
	},
	superAccess: false,
	hyperAccess: false,
	popup: {
		show: false,
		doNotShow: false,
		title: '',
		message: '',
	},
}

export const state = () => ({
	currentUser: currentUserDefault,
	setupInfo: setupInfoDefault,
	styleTheme: 'light',
})

export const getters = {
	currentUser: (state) => state.currentUser,
	setupInfo: (state) => state.setupInfo,
	styleTheme: (state) => state.styleTheme,
}

export const actions = {
	fetchCurrentUser({ commit, dispatch }) {
		return this.$api
			.get('/user')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_CURRENT_USER', data.currentUser)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSetupInfo({ commit, dispatch }) {
		return this.$api
			.get('/user/setupInfo')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SETUP_INFO', data.setupInfo)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchStyleTheme({ commit }) {
		return this.$api
			.get('/user/styleTheme')
			.then(({ data }) => {
				this.$vuetify.framework.theme.dark = data == 'dark' ? true : false
				commit('SET_STYLE_THEME', data)
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('SET_STYLE_THEME', 'light')
			})
	},
}

export const mutations = {
	SET_CURRENT_USER: (state, currentUser) => (state.currentUser = currentUser),
	REMOVE_CURRENT_USER: (state) => (state.currentUser = currentUserDefault),
	SET_SETUP_INFO: (state, setupInfo) => (state.setupInfo = setupInfo),
	REMOVE_SETUP_INFO: (state) => (state.setupInfo = setupInfoDefault),
	SET_STYLE_THEME: (state, styleTheme) => (state.styleTheme = styleTheme),
	UPDATE_LOCALE: (state, locale) => (state.currentUser.locale = locale),
	UPDATE_IMG_URL: (state, imgURL) => (state.currentUser.imgURL = imgURL),
}
