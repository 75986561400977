<template>
	<v-app>
		<LandingHeader />

		<v-main>
			<LandingCookies />

			<LandingHome />

			<LandingAbout />

			<LandingServices />

			<LandingNetworking />

			<LandingFeedback />

			<LandingContact />

			<LandingFooter />
		</v-main>
	</v-app>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('app.student.name'),
			titleTemplate: null,
			meta: [
				{
					name: 'description',
					content:
						'OverSOS es la evolución de SOS ETSEIB, nace de nuestra visión de expansión hacia otras universidades. Ahora puedes resolver tus dudas sobre las asignaturas preguntando en los apartados de asignatura.',
				},
			],
		}
	},
	components: {
		LandingHeader: () => import('@/components/landing/LandingHeader.vue'),
		LandingCookies: () => import('@/components/landing/LandingCookies.vue'),
		LandingHome: () => import('@/components/landing/LandingHome.vue'),
		LandingAbout: () => import('@/components/landing/LandingAbout.vue'),
		LandingServices: () => import('@/components/landing/LandingServices.vue'),
		LandingNetworking: () => import('@/components/landing/LandingNetworking.vue'),
		LandingFeedback: () => import('@/components/landing/LandingFeedback.vue'),
		LandingContact: () => import('@/components/landing/LandingContact.vue'),
		LandingFooter: () => import('@/components/landing/LandingFooter.vue'),
	},
}
</script>
