export const namespaced = true

export const state = () => ({})

export const getters = {}

export const actions = {
	updateUserPicture({ commit, dispatch }, { formData }) {
		return this.$api
			.post('/settings/user/picture', formData)
			.then(({ data }) => {
				if (data.success) {
					commit('user/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteUserPicture({ commit, dispatch }) {
		return this.$api
			.delete('/settings/user/picture')
			.then(({ data }) => {
				if (data.success) {
					commit('user/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserInfo({ commit, dispatch }, { name }) {
		return this.$api
			.post('/settings/user/info', {
				name,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserDescription({ commit, dispatch }, { description }) {
		return this.$api
			.post('/settings/user/description', {
				description,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserInterests({ commit, dispatch }, { lookingFor }) {
		return this.$api
			.post('/settings/user/interests', {
				lookingFor,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	switchAccountTheme({ commit, dispatch }, darkTheme) {
		return this.$api
			.post('/settings/account/darktheme', {
				darkTheme,
			})
			.then(({ data }) => {
				this.$vuetify.framework.theme.dark = data.dark
				if (data.dark) {
					commit('user/SET_STYLE_THEME', 'dark', { root: true })
				} else {
					commit('user/SET_STYLE_THEME', 'light', { root: true })
				}
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeLocale({ commit, dispatch }, locale) {
		return this.$api
			.post('/settings/account/locale', {
				locale: locale,
			})
			.then(({ data }) => {
				if (data.success) {
					this.$i18n.locale = locale.key
					commit('user/UPDATE_LOCALE', locale, { root: true })
					dispatch('user/fetchAvailableLanguages', null, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeEmail({ commit, dispatch }, email) {
		return this.$api
			.post('/settings/account/email', {
				email,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changePassword({ dispatch }, { currentPassword, newPassword, newPasswordAgain }) {
		return this.$api
			.post('/settings/account/password/change', {
				currentPassword,
				newPassword,
				newPasswordAgain,
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeNotifications({ commit, dispatch }, emails) {
		return this.$api
			.post('/settings/notifications', {
				promotional: emails.promotional,
				newsletter: emails.newsletter,
				reminders: emails.reminders,
				exams: emails.exams,
				academicYear: emails.academicYear,
				offerChanged: emails.offerChanged,
				newMessage: emails.newMessage,
				newCompanies: emails.newCompanies,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeAccountType({ commit, dispatch }, { accountType }) {
		return this.$api
			.post('/settings/account/accountType', {
				accountType,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeTalentPool({ commit, dispatch }, { talentPool }) {
		return this.$api
			.post('/settings/account/talentPool', {
				talentPool,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteAccount({ dispatch }, { password }) {
		return this.$api
			.post('/settings/account/delete', {
				password,
			})
			.then(({ data }) => {
				if (data.success) {
					dispatch('auth/logout')
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {}
