export const namespaced = true

export const state = () => ({
	application: null,
	applications: [],
})

export const getters = {
	application: (state) => state.application,
	applications: (state) => state.applications,
}

export const actions = {
	fetchApplications({ commit, dispatch }) {
		commit('SET_APPLICATIONS', [])
		return this.$api
			.get('/applications')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_APPLICATIONS', data.applications)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_APPLICATION: (state, application) => (state.application = application),
	SET_APPLICATIONS: (state, applications) => (state.applications = applications),
	OFFER_APPLIED: (state, application) => state.applications.unshift(application),
	DOCUMENT_UPLOADED_OFFER: (state, documents) => (state.application.documents = documents),
}
