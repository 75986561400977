export const namespaced = true

export const state = () => ({
	courses: [],
	course: null,
	availableCourses: [],
})

export const getters = {
	courses: (state) => state.courses,
	course: (state) => state.course,
	availableCourses: (state) => state.availableCourses,
}

export const actions = {
	fetchCourses({ commit, dispatch }) {
		commit('SET_COURSES', [])
		return this.$api
			.get('/courses')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COURSES', data.courses)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCourse({ commit, dispatch }, courseID) {
		commit('SET_COURSE', null)
		return this.$api
			.get(`/courses/${courseID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COURSE', data.course)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableCourses({ commit, dispatch }) {
		commit('SET_AVAILABLE_COURSES', [])
		return this.$api
			.get('/courses/available')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_COURSES', data.courses)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	leaveCourse({ commit, dispatch }, courseID) {
		return this.$api
			.post(`/courses/${courseID}/leave`)
			.then(({ data }) => {
				if (data.success) {
					commit('LEAVE_COURSE', courseID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	joinCourse({ commit, dispatch }, courseID) {
		return this.$api
			.post(`/courses/${courseID}/join`)
			.then(({ data }) => {
				if (data.success) {
					commit('JOIN_COURSE', data.course)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	share({ dispatch }, { course, gDriveEmail }) {
		return this.$api
			.post(`/courses/${course.id}/share`, {
				gDriveEmail,
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	unshare({ dispatch }, course) {
		return this.$api
			.post(`/courses/${course.id}/unshare`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadSchedule({ dispatch }, { courseID, degreeID, group }) {
		return this.$api
			.get(`/courses/${courseID}/practice/${degreeID}/group/${group}`, {
				responseType: 'blob',
			})
			.then(({ headers, data }) => {
				const filename = headers['content-disposition'].split('filename=')[1]
				const type = headers['content-type']
				const blob = new Blob([data], {
					type,
					encoding: 'UTF-8',
				})
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = filename
				link.click()
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_COURSES: (state, courses) => (state.courses = courses),
	SET_COURSE: (state, course) => (state.course = course),
	SET_AVAILABLE_COURSES: (state, availableCourses) => (state.availableCourses = availableCourses),
	JOIN_COURSE: (state, course) => state.courses.unshift(course),
	LEAVE_COURSE: (state, courseID) => (state.courses = state.courses.filter((course) => course.id !== courseID)),
}
