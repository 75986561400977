export const namespaced = true

export const state = () => ({
	folders: [],
	files: [],
	foldersHistory: [],
	loading: false,
})

export const getters = {
	folders: (state) => state.folders,
	files: (state) => state.files,
	foldersHistory: (state) => state.foldersHistory,
	currentFolder: (state) => state.foldersHistory[state.foldersHistory.length - 1],
	loading: (state) => state.loading,
}

export const actions = {
	fetchFolder({ commit, getters, dispatch }, { course, folder }) {
		commit('START_LOADING_EXPLORER')
		commit('SET_FOLDERS', [])
		commit('SET_FILES', [])
		if (folder.id) {
			const foldersHistory = getters.foldersHistory
			if (foldersHistory.length) {
				if (foldersHistory[foldersHistory.length - 1].id != folder.id) {
					foldersHistory.push({ name: folder.name, id: folder.id })
					commit('SET_FOLDERS_HISTORY', foldersHistory)
				}
			}
		} else {
			commit('SET_FOLDERS_HISTORY', [{ name: course.name, id: course.folderID }])
		}
		return this.$api
			.get(`/explorer/${course.id}/folder/${folder.id}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_FOLDERS', data.folders)
					commit('SET_FILES', data.files)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				commit('FINISH_LOADING_EXPLORER')
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('FINISH_LOADING_EXPLORER')
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	uploadFiles({ commit, getters, rootGetters, dispatch }, files) {
		commit('START_LOADING_EXPLORER')
		let foldersHistory = ''
		getters.foldersHistory.forEach((folder) => (foldersHistory += '/' + folder.name))

		const formData = new FormData()
		formData.append('filesLength', files.length)
		formData.append('foldersHistory', foldersHistory)
		files.forEach((file, i) => formData.append(`file_${i}`, file, file.name))

		return this.$api
			.post(`/explorer/${rootGetters['courses/course'].id}/folder/${getters.currentFolder.id}/upload`, formData)
			.then(({ data }) => {
				commit('FINISH_LOADING_EXPLORER')
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('FINISH_LOADING_EXPLORER')
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	createFolder({ commit, getters, rootGetters, dispatch }, { name }) {
		commit('START_LOADING_EXPLORER')
		let foldersHistory = ''
		getters.foldersHistory.forEach((folder) => (foldersHistory += '/' + folder.name))

		return this.$api
			.post(`/explorer/${rootGetters['courses/course'].id}/folder/${getters.currentFolder.id}/create`, {
				foldersHistory,
				name,
			})
			.then(({ data }) => {
				commit('FINISH_LOADING_EXPLORER')
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('FINISH_LOADING_EXPLORER')
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadFile({ commit, rootGetters, dispatch }, file) {
		commit('START_LOADING_EXPLORER')
		return this.$api
			.get(`/explorer/${rootGetters['courses/course'].id}/file/${file.id}/download`, {
				responseType: 'blob',
			})
			.then(({ headers, data }) => {
				const filename = headers['content-disposition'].split('filename=')[1]
				const type = headers['content-type']
				const blob = new Blob([data], {
					type,
					encoding: 'UTF-8',
				})
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = filename
				link.click()
				commit('FINISH_LOADING_EXPLORER')
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('FINISH_LOADING_EXPLORER')
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	reportProblem({ commit, getters, dispatch, rootGetters }, { reason, message, degreeID }) {
		commit('START_LOADING_EXPLORER')
		let foldersHistory = ''
		getters.foldersHistory.forEach((folder) => (foldersHistory += '/' + folder.name))

		return this.$api
			.post(`/explorer/${rootGetters['courses/course'].id}/report`, {
				reason,
				message,
				degreeID,
				foldersHistory,
			})
			.then(({ data }) => {
				commit('FINISH_LOADING_EXPLORER')
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('FINISH_LOADING_EXPLORER')
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_FOLDERS: (state, folders) => (state.folders = folders),
	SET_FILES: (state, files) => (state.files = files),
	SET_FOLDERS_HISTORY: (state, foldersHistory) => (state.foldersHistory = foldersHistory),
	HISTORY_GOBACK: (state, index) => (state.foldersHistory.length = index),
	START_LOADING_EXPLORER: (state) => (state.loading = true),
	FINISH_LOADING_EXPLORER: (state) => (state.loading = false),
}
