export const namespaced = true

export const state = () => ({
	documents: [],
	templates: [],
})

export const getters = {
	documents: (state) => state.documents,
	templates: (state) => state.templates,
}

export const actions = {
	fetchDocuments({ commit, dispatch }) {
		commit('SET_DOCUMENTS', null)
		return this.$api
			.get('/documents')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DOCUMENTS', data.documents)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchDocumentsTemplates({ commit, dispatch }) {
		commit('SET_DOCUMENTS_TEMPLATES', null)
		return this.$api
			.get('/documents/template')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DOCUMENTS_TEMPLATES', data.templates)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadDocument({ dispatch }, { documentID }) {
		return this.$api
			.get(`/documents/${documentID}`, {
				responseType: 'blob',
			})
			.then(({ headers, data }) => {
				try {
					const filename = headers['content-disposition'].split('filename=')[1]
					const type = headers['content-type']
					const blob = new Blob([data], {
						type,
						encoding: 'UTF-8',
					})
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = filename
					link.click()
				} catch {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	noDocument({ dispatch }) {
		dispatch('snackbar/push', { success: false, message: this.$i18n.t('documents.noDocument') }, { root: true })
	},
	deleteDocument({ dispatch }, { documentID }) {
		return this.$api
			.delete(`/documents/${documentID}`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('documents/fetchDocuments', null, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	toogleDocumentPrincipal({ dispatch }, { documentID, principal }) {
		return this.$api
			.post(`/documents/${documentID}`, {
				principal,
			})
			.then(({ data }) => {
				if (data.success) {
					dispatch('documents/fetchDocuments', null, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	uploadDocument({ dispatch }, { name, document, docType, principal }) {
		const formData = new FormData()
		formData.append('name', name)
		formData.append('document', document, document.name)
		formData.append('docType', docType)
		formData.append('principal', principal)
		return this.$api
			.put('/documents', formData)
			.then(({ data }) => {
				if (data.success) {
					dispatch('documents/fetchDocuments', null, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	uploadDocumentApplication({ commit, dispatch }, { documentID, applicationID }) {
		return this.$api
			.put(`/documents/application`, {
				documentID,
				applicationID,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('applications/DOCUMENT_UPLOADED_OFFER', data.documents, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadDocumentApplication({ dispatch }, { parentID }) {
		return this.$api
			.get(`/documents/application/${parentID}`, {
				responseType: 'blob',
			})
			.then(({ headers, data }) => {
				try {
					const filename = headers['content-disposition'].split('filename=')[1]
					const type = headers['content-type']
					const blob = new Blob([data], {
						type,
						encoding: 'UTF-8',
					})
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = filename
					link.click()
				} catch {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	uploadDocumentAttendance({ commit, dispatch }, { documentID, attendanceID }) {
		return this.$api
			.put(`/documents/attendance`, {
				documentID,
				attendanceID,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('attendances/DOCUMENT_UPLOADED_EVENT', data.documents, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	createDocumentsTemplate({ commit, dispatch }, { name, recordsID, cvID, motivationID }) {
		return this.$api
			.put('/documents/template', {
				name,
				recordsID,
				cvID,
				motivationID,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('DOCUMENT_TEMPLATE_CREATED', data.template)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteDocumentsTemplate({ commit, dispatch }, { templateID }) {
		return this.$api
			.delete(`/documents/template/${templateID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('DELETE_DOCUMENT_TEMPLATE', templateID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_DOCUMENTS: (state, documents) => (state.documents = documents),
	DOCUMENT_UPLOADED: (state, document) => state.documents.unshift(document),
	SET_DOCUMENTS_TEMPLATES: (state, templates) => (state.templates = templates),
	DOCUMENT_TEMPLATE_CREATED: (state, template) => state.templates.unshift(template),
	DELETE_DOCUMENT_TEMPLATE: (state, templateID) => (state.templates = state.templates.filter((e) => e.id != templateID)),
}
