import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import moment from 'moment'
import axios from 'axios'

import './registerServiceWorker'
import vuetify from './plugins/vuetify'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

let mode = 'production'
let sentryURL
let apiURL

if (mode == 'development') {
	apiURL = 'http://127.0.0.1:5000'
	sentryURL = ''
} else if (mode == 'testing') {
	apiURL = 'https://test.api.oversos.com'
	sentryURL = 'https://80e73dfdb3554cb3ae77e6e0e158ec1a@o143672.ingest.sentry.io/5509690'
} else if (mode == 'production') {
	apiURL = 'https://api.oversos.com'
	sentryURL = 'https://22fb2d73c6554cc4b6610b2ec129a0d4@o143672.ingest.sentry.io/5509676'
}

if (sentryURL) {
	Sentry.init({
		dsn: sentryURL,
		integrations: [
			new VueIntegration({
				Vue,
				tracing: true,
				attachProps: true,
			}),
			new Integrations.BrowserTracing(),
		],
		tracesSampleRate: 1.0,
	})
}

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

Vue.config.productionTip = false

const api = axios.create({
	baseURL: `${apiURL}/student`,
	timeout: 30000,
})

api.interceptors.request.use(
	(config) => {
		config.headers.Authorization = 'Bearer ' + store.getters['auth/accessToken']
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(response) => {
		if (response.data.refreshToken) store.commit('auth/AUTH_SUCCESS', response.data.refreshToken, { root: true })
		return response
	},
	(error) => {
		if (error.message === 'Network Error') {
			if (router.currentRoute.name != 'Error' && router.currentRoute.params.type != 'network')
				router.replace({ name: 'Error', params: { type: 'network' } })
		} else if (error.response.status === 401) {
			router.replace({ name: 'AuthLogout' })
		} else if (error.response.status === 403) {
			router.replace({ name: 'Error', params: { type: '403' } })
		} else if (error.response.status === 404) {
			router.replace({ name: 'Error', params: { type: '404' } })
		} else if (error.response.status === 410) {
			router.replace({ name: 'Error', params: { type: '410' } })
		} else if (error.response.status === 413) {
			router.replace({ name: 'Error', params: { type: '413' } })
		} else if (error.response.status === 500) {
			router.replace({ name: 'Error', params: { type: '500' } })
		}
		return Promise.reject(error)
	}
)

Vue.prototype.$api = api
Vue.prototype.$moment = moment
Vuex.Store.prototype.$api = api
Vuex.Store.prototype.$i18n = i18n
Vuex.Store.prototype.$moment = moment
Vuex.Store.prototype.$vuetify = vuetify

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: (h) => h(App),
}).$mount('#app')
