export const namespaced = true

export const state = () => ({
	loading: false,
	event: null,
	events: [],
	eventTypes: [],
})

export const getters = {
	loading: (state) => state.loading,
	event: (state) => state.event,
	events: (state) => state.events,
	eventTypes: (state) => state.eventTypes,
}

export const actions = {
	setLoadingEvent({ commit }, loading) {
		commit('SET_LOADING', loading)
	},
	fetchEvent({ commit, dispatch }, eventID) {
		commit('SET_EVENT', null)
		return this.$api
			.get(`/events/${eventID}`, {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success && data.attendance) {
					commit('SET_EVENT', data.event)
					commit('attendances/SET_ATTENDANCE', data.attendance, { root: true })
				} else if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchEvents({ commit, dispatch }) {
		commit('SET_EVENTS', [])
		return this.$api
			.get('/events/available', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENTS', data.events)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchEventsCompany({ commit, dispatch }, { companyID }) {
		commit('SET_EVENTS', [])
		return this.$api
			.get(`/events/available/company/${companyID}`, {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENTS', data.events)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchEventTypes({ commit, dispatch }) {
		commit('SET_EVENT_TYPES', [])
		return this.$api
			.get('/events/types')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT_TYPES', data.eventTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	attendEvent({ commit, dispatch }, { event, templateID, time }) {
		return this.$api
			.post(`/events/${event.id}/attend`, {
				templateID,
				time,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.attendance.event)
					commit('attendances/SET_ATTENDANCE', data.attendance, { root: true })
					commit('attendances/EVENT_ATTENDED', data.attendance, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	attendWebsite({ dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/meeting`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	missEvent({ commit, dispatch }, attendance) {
		return this.$api
			.delete(`/events/${attendance.event.id}/miss`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
					commit('attendances/EVENT_MISSED', attendance, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_LOADING: (state, loading) => (state.loading = loading),
	SET_EVENT: (state, event) => (state.event = event),
	SET_EVENTS: (state, events) => (state.events = events),
	SET_EVENT_TYPES: (state, eventTypes) => (state.eventTypes = eventTypes),
}
