export const namespaced = true

export const state = () => ({
	availableLanguages: [],
	availableSectors: [],
	availableSemesters: [],
	availableOfferTypes: [],
	availableDocumentTypes: [],
	availableThesisOptions: [],
	availableLanguageLevels: [],
})

export const getters = {
	availableLanguages: (state) => state.availableLanguages,
	availableSectors: (state) => state.availableSectors,
	availableSemesters: (state) => state.availableSemesters,
	availableOfferTypes: (state) => state.availableOfferTypes,
	availableDocumentTypes: (state) => state.availableDocumentTypes,
	availableThesisOptions: (state) => state.availableThesisOptions,
	availableLanguageLevels: (state) => state.availableLanguageLevels,
}

export const actions = {
	fetchAvailableLanguages({ commit, dispatch }) {
		return this.$api
			.get('/data/languages/web', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSemesters({ commit, dispatch }) {
		return this.$api
			.get('/data/semesters', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_SEMESTERS', data.semesters)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableOfferTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/offerTypes', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_OFFER_TYPES', data.offerTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableDocumentTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/documentTypes', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_DOCUMENT_TYPES', data.documentTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableThesisOptions({ commit, dispatch }) {
		return this.$api
			.get('/data/thesisOptions', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_THESIS_OPTIONS', data.thesisOptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableLanguageLevels({ commit, dispatch }) {
		return this.$api
			.get('/data/languageLevels', {
				params: {
					locale: this.$i18n.locale,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_LANGUAGE_LEVELS', data.languageLevels)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	contactSend({ dispatch }, { email, name, message }) {
		return this.$api
			.post('/data/contact', {
				params: {
					locale: this.$i18n.locale,
				},
				email,
				name,
				message,
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_AVAILABLE_LANGUAGES: (state, availableLanguages) => (state.availableLanguages = availableLanguages),
	SET_AVAILABLE_SECTORS: (state, availableSectors) => (state.availableSectors = availableSectors),
	SET_AVAILABLE_SEMESTERS: (state, availableSemesters) => (state.availableSemesters = availableSemesters),
	SET_AVAILABLE_OFFER_TYPES: (state, availableOfferTypes) => (state.availableOfferTypes = availableOfferTypes),
	SET_AVAILABLE_DOCUMENT_TYPES: (state, availableDocumentTypes) => (state.availableDocumentTypes = availableDocumentTypes),
	SET_AVAILABLE_THESIS_OPTIONS: (state, availableThesisOptions) => (state.availableThesisOptions = availableThesisOptions),
	SET_AVAILABLE_LANGUAGE_LEVELS: (state, availableLanguageLevels) => (state.availableLanguageLevels = availableLanguageLevels),
}
