export const namespaced = true

export const state = () => ({
	degrees: [],
})

export const getters = {
	degrees: (state) => state.degrees,
}

export const actions = {
	fetchDegrees({ commit, dispatch }) {
		commit('SET_DEGREES', [])
		return this.$api
			.get('/degrees')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DEGREES', data.degrees)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchMinDegreesFromFacultyAndUniversity({ commit, dispatch }, { faculty, university }) {
		commit('SET_DEGREES', [])
		return this.$api
			.get(`/degrees/min/faculty/${faculty.id}/university/${university.id}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DEGREES', data.degrees)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeDegree({ dispatch, rootGetters }, args) {
		return this.$api
			.post(`/degrees/${args.degree.id}/remove/${args.student.id}`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('studies/fetchStudies', null, { root: true })
					if (
						rootGetters['user/setupInfo'].verified.value &&
						rootGetters['user/setupInfo'].accountType.setup &&
						rootGetters['user/setupInfo'].accountType.student.value
					) {
						dispatch('courses/fetchCourses', null, { root: true })
					}
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_DEGREES: (state, degrees) => (state.degrees = degrees),
}
