export const namespaced = true

export const state = () => ({
	announcements: [],
	notices: [],
	posts: [],
	uploads: [],
	loading: false,
})

export const getters = {
	announcements: (state) => state.announcements,
	notices: (state) => state.notices,
	posts: (state) => state.posts,
	uploads: (state) => state.uploads,
	loading: (state) => state.loading,
}

export const actions = {
	fetchAnnouncements({ commit, dispatch }) {
		commit('SET_ANNOUNCEMENTS', [])
		return this.$api
			.get(`/feed/announcements`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_ANNOUNCEMENTS', data.announcements)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	aproveAnnouncement({ dispatch }, { announcement, aproved }) {
		return this.$api
			.post(`/feed/announcement/${announcement.id}/aprove`, {
				aproved,
			})
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	undoAproveAnnouncement({ dispatch }, { announcement }) {
		return this.$api
			.delete(`/feed/announcement/${announcement.id}/aprove`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchNoticesOffer({ commit, dispatch }, { offerID }) {
		commit('SET_NOTICES', [])
		return this.$api
			.get(`/feed/notices/offer/${offerID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_NOTICES', data.notices)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchNoticesEvent({ commit, dispatch }, { eventID }) {
		commit('SET_NOTICES', [])
		return this.$api
			.get(`/feed/notices/event/${eventID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_NOTICES', data.notices)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	aproveNotice({ dispatch }, { notice, aproved }) {
		return this.$api
			.post(`/feed/notice/${notice.id}/aprove`, {
				aproved,
			})
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	undoAproveNotice({ dispatch }, { notice }) {
		return this.$api
			.delete(`/feed/notice/${notice.id}/aprove`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchPosts({ commit, dispatch }, { courseID, page, perPage }) {
		commit('START_LOADING')
		commit('SET_POSTS', [])
		return this.$api
			.get(`/feed/posts/${courseID}`, {
				params: {
					page,
					perPage,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_POSTS', data.posts)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				commit('FINISH_LOADING')
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				commit('FINISH_LOADING')
			})
	},
	fetchUploads({ commit, dispatch }, { courseID, page, perPage }) {
		commit('START_LOADING')
		commit('SET_UPLOADS', [])
		return this.$api
			.get(`/feed/uploads/${courseID}`, {
				params: {
					page,
					perPage,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_UPLOADS', data.uploads)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				commit('FINISH_LOADING')
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				commit('FINISH_LOADING')
			})
	},
	publishPost({ commit, dispatch }, { courseID, title, content }) {
		return this.$api
			.put(
				'/feed/post',
				{
					title,
					content,
				},
				{
					params: {
						courseID,
					},
				}
			)
			.then(({ data }) => {
				if (data.success) {
					commit('NEW_POST', data.post)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editPost({ dispatch }, { post, title, content }) {
		return this.$api
			.post(
				'/feed/post',
				{
					title: title,
					content: content,
				},
				{
					params: {
						postID: post.id,
					},
				}
			)
			.then(({ data }) => {
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deletePost({ commit, dispatch }, post) {
		return this.$api
			.delete('/feed/post', {
				params: {
					postID: post.id,
				},
			})
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_POST', post.id)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	publishComment({ dispatch }, { post, content }) {
		return this.$api
			.put(
				'/feed/comment',
				{
					content,
				},
				{
					params: {
						postID: post.id,
					},
				}
			)
			.then(({ data }) => {
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editComment({ dispatch }, { comment, content }) {
		return this.$api
			.post(
				'/feed/comment',
				{
					content: content,
				},
				{
					params: {
						commentID: comment.id,
					},
				}
			)
			.then(({ data }) => {
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteComment({ dispatch }, args) {
		return this.$api
			.delete('/feed/comment', {
				params: {
					commentID: args.comment.id,
				},
			})
			.then(({ data }) => {
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	aprovePost({ dispatch }, args) {
		return this.$api
			.post(`/feed/post/${args.post.id}/aprove`, {
				aproved: args.aproved,
			})
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	undoAprovePost({ dispatch }, args) {
		return this.$api
			.delete(`/feed/post/${args.post.id}/aprove`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	aproveComment({ dispatch }, args) {
		return this.$api
			.post(`/feed/comment/${args.comment.id}/aprove`, {
				aproved: args.aproved,
			})
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	undoAproveComment({ dispatch }, args) {
		return this.$api
			.delete(`/feed/comment/${args.comment.id}/aprove`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_ANNOUNCEMENTS: (state, announcements) => (state.announcements = announcements),
	SET_NOTICES: (state, notices) => (state.notices = notices),
	SET_POSTS: (state, posts) => (state.posts = posts),
	SET_UPLOADS: (state, uploads) => (state.uploads = uploads),
	START_LOADING: (state) => (state.loading = true),
	FINISH_LOADING: (state) => (state.loading = false),
	NEW_POST: (state, post) => state.posts.unshift(post),
	REMOVE_POST: (state, id) => (state.posts = state.posts.filter((post) => post.id !== id)),
}
