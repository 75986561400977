export const namespaced = true

export const state = () => ({
	chats: [],
	chat: null,
})

export const getters = {
	chats: (state) => state.chats,
	chat: (state) => state.chat,
}

export const actions = {
	fetchChats({ commit, dispatch }) {
		commit('SET_CHATS', [])
		return this.$api
			.get('/chats')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_CHATS', data.chats)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchChat({ commit, dispatch }, chatID) {
		commit('SET_CHAT', null)
		return this.$api
			.get(`/chats/${chatID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_CHAT', data.chat)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	readAllMessages({ dispatch }, chatID) {
		return this.$api
			.get(`/chats/${chatID}/read/all`)
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	sendMessage({ dispatch }, { chat, content }) {
		return this.$api
			.post(`/chats/${chat.id}/send`, {
				content,
			})
			.then(({ data }) => {
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_CHATS: (state, chats) => (state.chats = chats),
	SET_CHAT: (state, chat) => (state.chat = chat),
}
