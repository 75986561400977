export const namespaced = true

export const state = () => ({
	gDriveEmails: [],
})

export const getters = {
	gDriveEmails: (state) => state.gDriveEmails,
}

export const actions = {
	fetchGDriveEmails({ commit, dispatch }) {
		return this.$api
			.get('/gdriveemails')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_GDRIVE_EMAILS', data.gDriveEmails)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addGDriveEmail({ commit, dispatch }, gDriveEmail) {
		return this.$api
			.put('/gdriveemails/add', {
				gDriveEmail,
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_GDRIVE_EMAIL', data.gDriveEmail)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeGDriveEmail({ commit, dispatch }, gDriveEmailID) {
		return this.$api
			.delete(`/gdriveemails/${gDriveEmailID}/remove`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_GDRIVE_EMAIL', gDriveEmailID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	doNotAskAgain({ commit, dispatch }) {
		return this.$api
			.get('/gdriveemails/skip')
			.then(({ data }) => {
				if (data.success) {
					commit('user/SET_SETUP_INFO', data.setupInfo, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_GDRIVE_EMAILS: (state, gDriveEmails) => (state.gDriveEmails = gDriveEmails),
	ADD_GDRIVE_EMAIL: (state, gDriveEmail) => state.gDriveEmails.push(gDriveEmail),
	REMOVE_GDRIVE_EMAIL: (state, gDriveEmailID) => (state.gDriveEmails = state.gDriveEmails.filter((gDriveEmail) => gDriveEmail.id !== gDriveEmailID)),
}
