export const namespaced = true

export const state = () => ({
	notifications: [],
})

export const getters = {
	notifications: (state) => state.notifications,
	unreadNotifications: (state) => state.notifications.filter((notification) => notification.read === false),
}

export const actions = {
	fetchNotifications({ commit, dispatch, rootGetters }) {
		commit('SET_NOTIFICATIONS', [])
		if (rootGetters['auth/authenticated']) {
			return this.$api
				.get('/notifications')
				.then(({ data }) => {
					if (data.success) {
						commit('SET_NOTIFICATIONS', data.notifications)
					} else {
						dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
					}
					return data
				})
				.catch((error) => {
					console.log(error)
					dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				})
		}
	},
	readNotification({ commit, dispatch }, notification) {
		return this.$api
			.post(`/notifications/${notification.id}/read`)
			.then(({ data }) => {
				if (data.success) {
					commit('READ_NOTIFICATION', notification)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	readAllNotifications({ commit, dispatch }) {
		return this.$api
			.post('/notifications/all/read')
			.then(({ data }) => {
				if (data.success) {
					commit('READ_ALL_NOTIFICATIONS')
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_NOTIFICATIONS: (state, notifications) => (state.notifications = notifications),
	READ_NOTIFICATION: (state, notification) => (state.notifications[state.notifications.indexOf(notification)].read = true),
	READ_ALL_NOTIFICATIONS: (state) => state.notifications.forEach((notification) => (notification.read = true)),
}
