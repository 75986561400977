export const namespaced = true

export const state = () => ({
	exams: [],
})

export const getters = {
	exams: (state) => state.exams,
}

export const actions = {
	fetchExams({ commit, dispatch }) {
		commit('SET_EXAMS', [])
		return this.$api
			.get('/exams')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EXAMS', data.exams)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
}

export const mutations = {
	SET_EXAMS: (state, exams) => (state.exams = exams),
}
