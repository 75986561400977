import Vue from 'vue'
import Vuex from 'vuex'

import * as applications from './modules/applications.js'
import * as attendances from './modules/attendances.js'
import * as auth from './modules/auth.js'
import * as companies from './modules/companies.js'
import * as courses from './modules/courses.js'
import * as cv from './modules/cv.js'
import * as data from './modules/data.js'
import * as documents from './modules/documents.js'
import * as exams from './modules/exams.js'
import * as explorer from './modules/explorer.js'
import * as events from './modules/events.js'
import * as feed from './modules/feed.js'
import * as offers from './modules/offers.js'
import * as sidebar from './modules/sidebar.js'
import * as degrees from './modules/degrees.js'
import * as faculties from './modules/faculties.js'
import * as notifications from './modules/notifications.js'
import * as universities from './modules/universities.js'
import * as gdriveemails from './modules/gdriveemails.js'
import * as dialog from './modules/dialog.js'
import * as popup from './modules/popup.js'
import * as snackbar from './modules/snackbar.js'
import * as studies from './modules/studies.js'
import * as sponsors from './modules/sponsors.js'
import * as settings from './modules/settings.js'
import * as chats from './modules/chats.js'
import * as splash from './modules/splash.js'
import * as user from './modules/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		applications,
		attendances,
		auth,
		companies,
		courses,
		cv,
		data,
		documents,
		exams,
		explorer,
		events,
		feed,
		offers,
		degrees,
		faculties,
		notifications,
		universities,
		gdriveemails,
		sidebar,
		dialog,
		popup,
		snackbar,
		studies,
		sponsors,
		settings,
		chats,
		splash,
		user,
	},
	plugins: [],
})
