<template>
	<v-app>
		<Splash />
		<Sidebar v-if="authenticated" />
		<Navbar />
		<v-main app>
			<div class="mt-5 mx-3 d-flex overflow-auto" v-if="$vuetify.breakpoint.smAndDown && !routeNoAds">
				<Ad :sponsor="sponsors.mobile.left" :device="'mobile'" :position="1" />
				<Ad :sponsor="sponsors.mobile.right" :device="'mobile'" :position="2" />
			</div>
			<div class="d-flex justify-space-between" :style="{ marginBottom: marginBottom }">
				<div class="flex-grow-1">
					<router-view />
				</div>
				<div class="d-flex-column justify-space-between" v-if="$vuetify.breakpoint.mdAndUp && !routeNoAds" style="min-width: 180px">
					<Ad :sponsor="sponsors.desktop.top" :device="'desktop'" :position="1" />
					<Ad :sponsor="sponsors.desktop.bottom" :device="'desktop'" :position="2" />
				</div>
			</div>

			<Dialog />
			<SnackBar />
			<PopUp />
			<Footer />
		</v-main>
		<BottomNavbar v-if="$vuetify.breakpoint.mdAndDown && authenticated" />
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	components: {
		Sidebar: () => import('@/components/main/Sidebar.vue'),
		Navbar: () => import('@/components/main/Navbar.vue'),
		Footer: () => import('@/components/main/Footer.vue'),
		BottomNavbar: () => import('@/components/main/BottomNavbar.vue'),
		PopUp: () => import('@/components/main/PopUp.vue'),
		Dialog: () => import('@/components/feedback/Dialog.vue'),
		SnackBar: () => import('@/components/feedback/SnackBar.vue'),
		Splash: () => import('@/components/feedback/Splash.vue'),
		Ad: () => import('@/components/sponsors/Ad.vue'),
	},
	computed: {
		marginBottom() {
			if (this.$vuetify.breakpoint.lgAndUp) return '50px'
			else if (!this.authenticated) return '100px'
			else return 0
		},
		routeNoAds() {
			let noAdsRoutes = [
				'AuthLogin',
				'AuthLogout',
				'AuthRegister',
				'AuthResetPassword',
				'Landing',
				'Offers',
				'Offer',
				'Applications',
				'Companies',
				'Company',
				'CompanyProfile',
				'CompanyOffers',
				'CompanyEvents',
				'Messages',
				'MessagesChat',
				'Sponsors',
				'SponsorEIC',
				'SponsorFUE',
				'Events',
				'Event',
				'Attendances',
			]
			if (!this.authenticated) {
				noAdsRoutes.push('Contact', 'FAQ', 'Team', 'Policies', 'PoliciesCookies', 'PoliciesPrivacy', 'PoliciesTerms')
			}
			if (noAdsRoutes.includes(this.$route.name)) return true
			return false
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			sponsors: 'sponsors/sponsors',
		}),
	},
}
</script>
