<template>
	<div>
		<router-view />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo: {
		title: 'App',
		titleTemplate: '%s | OverSOS Student',
		meta: [{ charset: 'utf-8' }],
	},
	data() {
		return {
			timerFetchSponsors: null,
			timerFetchNotifications: null,
		}
	},
	computed: {
		darkTheme() {
			return this.styleTheme === 'dark'
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
			styleTheme: 'user/styleTheme',
		}),
	},
	created() {
		if (this.authenticated) {
			this.refreshAccess()
				.then(({ setupInfo }) => {
					this.update_locale()
					this.check_verified(setupInfo).then(
						(verified) => {
							if (verified.value) {
								console.log('App initialization: user verified')
								this.remind_setup().then((setupDone) => {
									if (setupDone) {
										console.log(`App initialization: setup ${setupDone ? 'done' : 'not done'}`)
									}
								})
							} else {
								console.log('App initialization: user not verified')
							}
						},
						() => {
							console.log('App initialization: user not verified')
						}
					)
				})
				.catch((error) => {
					console.log(error)
				})
		} else {
			this.fetchSponsors()
			this.update_locale()
		}
		this.timerFetchSponsors = setInterval(this.fetchSponsors, 300000)
		this.timerFetchNotifications = setInterval(this.fetchNotifications, 60000)
	},
	beforeUpdate() {
		if (this.setupInfo.setup.description) this.check_verified(this.setupInfo)
	},
	watch: {
		styleTheme() {
			this.$vuetify.theme.dark = this.darkTheme
		},
	},
	methods: {
		cancelAutoUpdate() {
			clearInterval(this.timerFetchSponsors)
			clearInterval(this.timerFetchNotifications)
		},
		update_locale() {
			if (this.authenticated) {
				this.$i18n.locale = this.currentUser.locale.key
				this.$moment.locale(this.currentUser.locale.key)
			} else {
				const lang = this.$route.query.lang
				// TODO: Not working, lang is undefined at refresh -.-
				if (lang) {
					if (['en', 'es', 'ca'].includes(lang)) {
						this.$i18n.locale = lang
						this.$moment.locale(lang)
					}
				} else {
					this.$i18n.locale = this.getBrowserLocale()
					this.$moment.locale(this.getBrowserLocale())
				}
			}
		},
		check_verified(setupInfo) {
			return new Promise((resolve, reject) => {
				if (this.authenticated && !setupInfo.verified.value && this.$route.name != 'AuthLogout') {
					if (!this.routeIsSafeUnverified()) this.$router.push({ name: 'AuthVerify' })
					resolve(false)
				} else if (this.authenticated) {
					resolve(true)
				} else {
					reject()
				}
			})
		},
		routeIsSafeUnverified() {
			const safeRoutes = ['AuthVerify', 'Policies', 'PoliciesCookies', 'PoliciesPrivacy', 'PoliciesTerms', 'Contact', 'FAQ', 'Team']
			if (safeRoutes.includes(this.$route.name)) return true
			return false
		},
		remind_setup() {
			return new Promise((resolve, reject) => {
				if (
					this.authenticated &&
					(!this.setupInfo.setup.gDriveEmail ||
						!this.setupInfo.studies.verifiedStudies ||
						(this.setupInfo.accountType.student.value && this.setupInfo.studies.allAlumni))
				) {
					if (!this.routeIsSafeNotSetup()) this.$router.push({ name: 'AuthSetup' })
					resolve(false)
				} else if (this.authenticated) {
					resolve(true)
				} else {
					reject()
				}
			})
		},
		routeIsSafeNotSetup() {
			const safeRoutes = ['Policies', 'PoliciesCookies', 'PoliciesPrivacy', 'PoliciesTerms', 'Contact', 'FAQ', 'Team']
			if (safeRoutes.includes(this.$route.name)) return true
			return false
		},
		getBrowserLocale() {
			let language
			navigator.languages.forEach((locale) => {
				const localeLang = locale.split('-')[0]
				if (['en', 'es', 'ca'].includes(localeLang) && !language) {
					language = localeLang
				}
			})
			return language ? language : 'en'
		},
		...mapActions('auth', ['refreshAccess']),
		...mapActions('sponsors', ['fetchSponsors']),
		...mapActions('notifications', ['fetchNotifications']),
	},
	beforeDestroy() {
		clearInterval(this.timerFetchSponsors)
		clearInterval(this.timerFetchNotifications)
	},
}
</script>

<style>
.v-chip__content {
	white-space: normal;
}
#app {
	background: var(--v-background-base);
}
.v-dialog .v-card {
	background: var(--v-background-base) !important;
}
.v-dialog {
	border-radius: 24px !important;
}
.v-menu__content .v-card {
	background: var(--v-background-base) !important;
}
</style>
