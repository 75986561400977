export const namespaced = true

export const state = () => ({
	active: false,
})

export const getters = {
	active: (state) => state.active,
}

export const actions = {
	open({ commit }) {
		commit('CHANGE_ACTIVE', true)
	},
	close({ commit }) {
		commit('CHANGE_ACTIVE', false)
	},
}

export const mutations = {
	CHANGE_ACTIVE: (state, active) => (state.active = active),
}
